
            @import 'src/styles/mixins.scss';
        
.sliderMainContainer {
    border-radius: 20px;
    background: #fff;
    height: 500px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 40px 32px;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    @include sm {
        display: block;
        height: 365px;
        width: 550px;
    }
    @include xxxl {
        width: 650px;
        height: 415px;
    }
}
.sliderMainContainerDeferentStyle {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.15);
    height: 222px;
    padding: 24px 20px 29px 20px;
    flex-shrink: 0;
    width: 100%;
    margin: 0 auto;
    @include sm {
        height: 290px;
        padding: 50px 35px 70px 35px;
        width: 352px;
    }
    @include xxxl {
        width: 502px;
        height: 390px;
    }
}

.dotsStyle {
    padding-bottom: 3px;
    button {
        &::before {
            color: #b083e0 !important;
            font-size: 11px !important;
        }
    }
}

.slide {
    padding: 15px 20px;
    @include lg {
        padding: 18px 0;
    }
    @include xxxl {
        padding: 18px 0;
    }
}
