
            @import 'src/styles/mixins.scss';
        
.bgStyle {
    padding: 70px 0 63px 0;
    margin-top: 100px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    scroll-margin-block-start: 70px;
    @include lg {
        padding: 110px 73px 82px 81px;
        background-color: #fff;
        border-radius: 20px;
        margin-top: 180px;
        flex-direction: row;
    }
    @include xxxl {
        padding: 130px 93px 102px 101px;
        gap: 190px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        opacity: 1;
        background: #fff;

        @include lg {
            left: 0;
            top: 55px;
            width: 100%;
            transform: translateX(0);
            height: 100%;
            opacity: 0.3;
            background: none;
            background-image: url("/assets/images/whatClientsSayLeftImg.png");
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1003px;
        width: 100%;
        height: 100%;
        border-radius: 0 0 0 20px;
        background-image: url("/assets/images/whatClientsSayRightImg.png");
        background-size: contain;
        background-repeat: no-repeat;
        display: none;

        @include lg {
            display: block;
        }
        @include xxxl {
            right: -1605px;
        }
    }
}
