
            @import 'src/styles/mixins.scss';
        
.bgStyle {
    position: relative;
    margin-top: 100px;
    @include xxxl {
        margin-top: 130px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background-color: #f7f2fb;
        z-index: -10;
    }
}
