
            @import 'src/styles/mixins.scss';
        
.bgStyle {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: calc(100% + 45px);
        top: -45px;
        @include md {
            top: -131px;
            height: calc(100% + 131px);
        }
        position: absolute;
        background-image: linear-gradient(
            to left,
            rgba(253, 232, 241, 1),
            rgba(255, 255, 255, 1),
            rgba(239, 231, 255, 1)
        );
        z-index: -10;
    }
}

.hero-section {
    min-height: calc(100vh - 131px);
    @media (min-height: 1000px) {
        padding-top: 8rem;
    }
}
