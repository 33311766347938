
            @import 'src/styles/mixins.scss';
        
.bgStyle {
    background-color: #e5d6f5;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 78px 27px 54px 27px;
    position: relative;
    color: #351c4f;
    @include lg {
        padding: 90px 8px 79px 80px;
    }
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 270px;
        height: 180px;
        background-image: url("/assets/images/whyPartnerWithUsImg2.png");
        background-size: contain;
        background-repeat: no-repeat;
        @include lg {
            width: 743px;
            height: 491px;
        }
    }
}
