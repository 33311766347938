
            @import 'src/styles/mixins.scss';
        
.bgStyleBefore {
    position: relative;
    padding-top: 80px;
    @include lg {
        height: 100vh;
        padding-top: 18vh;
    }
}
.bgStyleAfter {
    top: 3800px;
}

.fixed {
    background-color: #f7f2fb;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    padding-top: 18vh;
}
