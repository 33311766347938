
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.scrolled[data-animated="true"] {
    overflow: hidden;
}
.scrolled__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.scrolled[data-animated="true"] .scrolled__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll forwards linear infinite;
    animation-duration: var(--animationDuration);
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 0.5rem));
    }
}
